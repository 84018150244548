<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="昵称/手机号码">
              <a-input v-model="queryParam.kw" placeholder="请输入昵称/手机号码" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="关注日期">
              <a-range-picker
                v-model="date"
                format="YYYY-MM-DD HH:mm:ss"
                @change="onChange"
                :show-time="{ format: 'HH:mm:ss' }"/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="reload" @click="clearClick">清空</a-button>
            </span>
          </a-col>
          <!--          <a-col :md="2" :sm="24">-->
          <!--            <span class="table-page-search-submitButtons">-->
          <!--              <a-button type="primary" :loading="downLoad" icon="vertical-align-bottom" @click="exportClick">导出</a-button>-->
          <!--            </span>-->
          <!--          </a-col>-->
        </a-row>
      </a-form>
    </div>
    <a-table
      :columns="columns"
      :dataSource="data"
      rowKey="id"
      :loading="loading"
      :pagination="pagination"
      @change="pageChange"
    >
      <span slot="headImgUrl" slot-scope="text">
        <a-avatar :src="text" icon="user" />
      </span>
      <!--      <span slot="action" slot-scope="text">-->
      <!--        <a-button type="primary" size="small" @click="detail(text)">-->
      <!--          详情-->
      <!--        </a-button>-->
      <!--      </span>-->
    </a-table>
  </a-card>
</template>

<script>
// import { findCallRecord, exportZip } from '@/api/outbound/call'
// import fileDownload from 'js-file-download'
export default {
  name: 'PhoneManage',
  data() {
    return {
      pagination: {
        current: 1,
        total: 0,
        showTotal: function(total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50']
      },
      loading: false,
      // 查询参数
      queryParam: {
        page: 1,
        size: 10,
        kw: null,
        startTime: null,
        endTime: null
      },
      data: [],
      date: [],
      downLoad: false,
      columns: [
        // {
        //   title: '序号',
        //   dataIndex: 'id'
        // },
        {
          title: '头像',
          dataIndex: 'headImgUrl',
          scopedSlots: { customRender: 'headImgUrl' }
        },
        {
          title: '昵称',
          dataIndex: 'nickname'
        },
        {
          title: '下段手机号码',
          dataIndex: 'phone'
        },
        {
          title: '关注时间',
          dataIndex: 'subscribeTime'
        },
        // {
        //   title: '所属快递站',
        //   dataIndex: 'phone'
        // },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: process.env.VUE_APP_API_DELIVERY_BASE_URL
    }
  },
  created() {
    this.query()
  },
  methods: {
    onChange(date, dateString) {
      console.log(date)
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    pageChange(pageNumber) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    queryClick() {
      this.pagination.current = 1
      this.queryParam.page = 1
      this.query()
    },
    clearClick() {
      this.queryParam = this.$options.data().queryParam
      this.date = []
    },
    // exportClick () {
    //   const data = { ...this.queryParam }
    //   delete data.size
    //   delete data.page
    //   if (data.phones) {
    //     data.phones = data.phones.split(',')
    //   }
    //   this.downLoad = true
    //   exportZip(data).then(res => {
    //     // if (res.status !== 200 || res.status === 400) {
    //     //   throw new Error(res.message)
    //     // }
    //     fileDownload(res, `${this.fileName}.zip`)
    //   }).catch((e) => {
    //     this.$notification.error({
    //       message: '提示',
    //       description: e.message
    //     })
    //   }).finally(() => {
    //     this.downLoad = false
    //   })
    // },
    query() {
      console.log(this.queryParam)
      const data = { ...this.queryParam }
      if (data.phones) {
        data.phones = data.phones.split(',')
      }
      this.loading = true
      this.axios
        .get(`${this.url}/delivery-cloud/user-mapping/page`, { params: data })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.data = res.body.list
          this.pagination.total = res.body.total
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped></style>
